import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider, CSSReset, Box } from '@chakra-ui/react'
import { IntlProvider } from 'react-intl';

import theme2 from "../../theme/";

import messagesFr from '../locales/fr'
import messagesEn from '../locales/en'

import '../../assets/fonts/stylesheet.css'
const messages = { ...messagesFr, ...messagesEn }

let lang = process.env.GATSBY_LANG
let i18nConfig = {
    locale: lang,
    messages: messages
};

const LayoutAccount = ({ children }) => {
    return (
        <IntlProvider
            locale={lang}
            defaultLocale={i18nConfig.locale}
            messages={i18nConfig.messages[lang]}
        >
            <CSSReset />
            <Box
                as="section"
                borderLeft="solid 10px"
                borderLeftColor="#0042da"
                minHeight="100vh"
                w={"calc(100vw - 0)"}
                pb={{ base: '100px', md: '0' }}
            >
                {children}
            </Box>
        </IntlProvider>
    )
}

LayoutAccount.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LayoutAccount